import React from "react"
import Layout from "../../components/layout"

import { Helmet } from "react-helmet"
import { Container, Row, Col, Nav } from "react-bootstrap"

const Chapters = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "info short" }} />
      <Layout>
        <Container className="info-contain">
          <Row>
            <Col lg={10}>
              <h1 className="page-title">Publications</h1>
              <div className="publication-section">
                <Nav variant="tabs" defaultActiveKey="/publications/chapters">
                  <Nav.Item>
                    <Nav.Link href="/publications/all">All</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/articles">Articles</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/chapters">Chapters</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/patents">Patents</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <p className="resume-text">
                <span className="bold author">CHAPTERS</span>
              </p>

              <div className="publication-unit">
                <p className="publication-title">
                  [02] Dilution-to-extinction platform for the Isolation of
                  marine bacteria-producing antitumor compounds
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> X. Benitez,{" "}
                  <span className="author">E. G. Gonzalez,</span> J. Garcia, P.
                  Zuñiga, F. de la Calle
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2021 Methods in
                  Molecular Biology
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1007/978-1-0716-1358-0_5
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [01] Microsatellite DNA capture from enriched libraries
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> R. Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2013 in{" "}
                  <span className="italic">Methods of Molecular Biology,</span>{" "}
                  Springer, New York. Vol. 1006. Pp: 67-87
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Chapters
